/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-zy8msw --style3 --full" anim={""} name={"einleitung"} animS={"5"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 flex--center" anim={"2"} animS={"4"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 mt--10" anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--86 lh--1" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Hotel</span><br>"}>
              </Title>

              <Title className="title-box fs--154 swpf--uppercase lh--1 mt--0" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Groß</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--cColor1 fs--20 swpf--uppercase mt--20" content={"Verfügbare Zimmer"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--40" name={"einleitung-1"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":500}}>
              
              <Title className="title-box" content={"Hotel Groß ist ein <span style = \"font-style: italic;\"> Fünf-Sterne-Hotel </span> in Budweis"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Button className="btn-box swpf--uppercase" content={"Verfügbare Zimmer"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/142/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/142/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/142/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/142/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/142/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/142/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"information"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(255, 255, 255);\">Casino</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(255, 255, 255);\">Fitness</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(255, 255, 255);\">Sauna</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(255, 255, 255);\">Wi-fi</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(255, 255, 255);\">Restaurant</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(255, 255, 255);\">Bar</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-zy8msw --center pb--60 pt--60" name={"information-1"} style={{"backgroundColor":"var(--color-supplementary)"}} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/142/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":860}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 pb--25 pt--25" content={"<span style=\"color: rgb(255, 255, 255);\">Erleben Sie unvergessliche Momente im Grandhotel</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Adresse<br>"}>
              </Title>

              <Text className="text-box" content={"Hotel Groß<br>Oeder Weg 22<br>Tschechische Republik"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--43 swpf--uppercase ls--0 lh--1" content={"Hotel<br>Groß<br>"}>
              </Title>

              <Button className="btn-box fs--16 swpf--uppercase" content={"Verfügbare Zimmer"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box" content={"+49 797 811 2X05<br>info@vase-stranky.com<br>Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}